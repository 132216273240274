@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import './assets/scss/vaiables';

* {
  margin: 0;
  top: 0;
  box-sizing: border-box;
  outline: none;
  box-shadow: none;
  list-style: none;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

.p-inputtext {
  box-shadow: none !important;

  &:enabled:focus {
    box-shadow: none !important;
  }
}

.p-dropdown {
  &.p-focus {
    box-shadow: none !important;
  }

  &:hover {
    box-shadow: none !important;
  }
}

.p-button-danger {
  background-color: $main-color !important;
  border-color:  $main-color !important;
  .p-button.p-button-danger:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px $main-color, 0 1px 2px 0 black;
}
  &.w-full {
    display: flex;
    justify-content: center;
  }
}
.comp-randevu{
  display:none;
}
.work-comp{
  position: relative;
  &::after{
    content:'';
    width: 92%;
    height:1px;
    background-color: rgba(182, 182, 182, 0.8);
    position:absolute;
    bottom:0;
    left: 80px;
  }
}
.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .comp-randevu{
    display:block;
  }
}


