@import "../../../assets/scss/vaiables";

.nav {
  padding: 0 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $main-color;
  z-index: 9999;

  .menu-cards {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: 0.4s;
    display: flex;
    justify-content: end;

    &.active {
      opacity: 1;
      visibility: visible;
      left: 0;

    }
  }

  &-logo {
    display: inline-block;
    width: 104px;
    height: 76px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 120px;

    li {

      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        text-decoration: none;
        transition: all 400ms;
        cursor: pointer !important;

        &:hover {
          opacity: 0.6;
        }

        &.active {
          text-decoration: underline;
        }
      }
    }
  }

  .button {
    background-color: #fff;
    border-radius: 10px;
    color: $main-color;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    width: 178px;
    height: 48px;
    line-height: 48px;
    text-align: center;
  }

  .menu-logo {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .nav {
    ul {
      gap: 30px;
      margin-right: 10px;
    }

    .button {
      width: 200px;
    }
  }
}

@media screen and (max-width: 768px) {
  .nav {
    padding: 12px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .comp-title, .button {
      display: none;
    }

    .menu-logo {
      display: block;
      cursor: pointer;
      color: #fff;
      font-size: 24px;
    }

    &-logo {
      width: 100px;
      height: 41px;
    }
  }
}
