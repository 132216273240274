.about-us{
  padding:70px 0 60px 80px ;
  display: flex;
  justify-content: center;
  gap: 65px;
  .about-title{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #575757;
    margin-bottom:10px;
  }
  .question{
    font-weight: 600;
    font-size: 52px;
    line-height: 63px;
    color: #252525;
    margin-bottom: 30px;
    span{
      color: #eb370d;
    }
  }
  .about-desc{
    font-size: 22px;
    line-height: 160%;
    color: rgba(88, 88, 88, 0.9);
    opacity: 0.8;

  }
  .right{
    img{
      width: 559px;
      height: 517px;
    }
  }
}

@media screen and (max-width:1200px) {
  .about-us{
    flex-direction:column;
    padding: 70px 64px 80px;
    .right{
      img{
        width: 669px;
      }
    }
  }
}

@media screen and (max-width:768px){
  .about-us{
    padding:36px 0 50px ;
    flex-direction: column;
    gap: 10px;
    .left{
      padding:0 32px;
    }
    .about-title{
      display:none;
    }
    .question{
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 24px;
    }
    .about-desc{
      font-size: 16px;
      color: #777777;
    }
    .right{

      img{
        width: 100%;
        height: 341px;
      }
    }
  }
}